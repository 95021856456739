.dashboard-setup-status-bar {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dashboard-setup-status-bar .account-status-div {
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
  border-radius: 5px;
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-size: .8rem;
}

.dashboard-setup-status-bar .account-status-div p:nth-child(1) {
  color: var(--secondary-gray);
}

.dashboard-setup-status-bar .account-status-div p:nth-child(2) {
  font-size: 1.09rem;
  font-weight: bold;
  margin-top: 5px;
}

.dashboard-setup-status-bar .status-box {
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
  border-radius: 5px;
  width: 270px;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px;
}

.dashboard-setup-status-bar button {
  margin-top: 20px;
  width: 250px;
}

.dashboard-setup-status-bar .inactive {
  color: var(--primary-yellow); 
}

.dashboard-setup-status-bar .active {
  color: var(--primary-green); 
}

.display-nothing{
  height: 30px;
  width: 20px;
  border-radius: 50%;
  background-color: transparent;
}

.step-status-circle-green {
  height: 20px;
  width: 20px;
  transform: translate(0%, 10%);
  position: relative; 
}

.status-box ul {
  list-style-type: none;
  padding: 0;
  line-height: 1.7;
  display: flex;
  justify-content: center;
  padding-inline-start: 0 !important
}

.status-box .status-item {
  width: 250px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  z-index: -10;
}

.status-item-content {
  width: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  font-weight: bold;
}

.status-item-active {
  background-color: rgba(244, 189, 70, 0.217);
  font-weight: bold;
  color: var(--primary-yellow);
}

.under-review-status-bar div {
  cursor: not-allowed;
}