#pricing-formula-modal {
    color: var(--primary-blue);
    background-color: var(--primary-white);
    border-radius: 10px;
    max-width: 400px;
    min-width: 320px;
    padding: 25px;
    margin-top: 40px;
    box-sizing: border-box;
}

#pricing-formula-modal .modal-header {
    align-items: normal;
    margin-bottom: 20px;
}

#pricing-formula-modal h3 {
    margin: 20px 0 10px 0;
}

#pricing-formula-modal .textarea-header {
    margin: 0;
    margin-bottom: 10px;
}

#pricing-formula-modal .formula-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

#pricing-formula-modal textarea {
    width: 91%;
    height: 60px;
    resize: none;
    padding: 15px;
    color: var(--primary-gray);
    outline: none;
    border-radius: 4px;
}

#pricing-formula-modal .formula-valid {
    border: 2px solid var(--primary-blue);
}

#pricing-formula-modal .formula-invalid {
    border: 2px solid var(--primary-yellow);
}

#pricing-formula-modal button {
    width: 50%;
}

/* Pricing Formula Tips */
.pricing-formula-tips ul li {
    all: unset;
    display: list-item;
    line-height: 1.2;
    pointer-events: none;
    font-size: 0.8rem;
}

.pricing-formula-tips p {
    color: var(--primary-gray);
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 4px;
}

.pricing-formula-tips ul li:hover {
    background-color: transparent;
}

.pricing-formula-tips-top {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    align-items: start;
}

.pricing-formula-tips span,
#square-footage-ranges li {
    color: var(--primary-gray);
    font-size: 0.8rem;
}

.pricing-formula-tips span {
    margin-bottom: 15px;
    display: block;
}

.pricing-formula-tips p {
    margin: 2px 0;
}

.pricing-formula-tips .no-margin-span {
    margin: 0;
}

.pricing-formula-tips .advanced-operators {
    display: flex;
    align-items: center;
}

.pricing-formula-tips .avail-inputs-div {
    display: flex;
    align-items: center;
}

.pricing-formula-tips .avail-inputs-div h3 {
    margin: 0;
}

.pricing-formula-tips .tooltip .tooltiptext {
    bottom: 15px;
}