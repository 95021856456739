.payment-confirmation-all {
    background-color: var(--primary-white);
    padding: 40px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 600px;
    box-sizing: border-box;
}

.payment-confirmation-all p{
    font-size: 15px;
    line-height: 20px;
}

.payment-confirmation {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    gap: 30px;
    border-top: 1px solid var(--secondary-gray)
}

.top-payment-confirmation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}
.payment-confirmation-order-id {
    margin-top: 5px;
}

.order-confirmation {
    margin-bottom: 0;
}

.view-all-orders-button {
    background-color: var(--secondary-white);
    color: var(--primary-blue);
    width: auto;
    height: auto;
    font-size: 15px;
    padding: 8px 15px;
    border-radius: 6px;
    margin: -50px 0 0 0;
    align-self: flex-end;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 0px;
    right: 0px;
}

.view-all-orders-button:hover {
    background-color: var(--secondary-white);
    color: var(--primary-blue);
}

.payment-confirmation h3 {
    margin-bottom: 10px;
    font-size: 16px;
}

.details-payment-confirmation {
    margin-bottom: 5px;
    font-size: 16px;
}

.payment-confirmation p {
    margin-top: 10px;
}

.left-column, .right-column {
    flex: 1;
}

.left-column {
    display: flex;
    flex-direction: column;
}

.right-column {
    display: flex;
    flex-direction: column;
}

.order-confirmation, .appointment-details, .instructions {
    margin-bottom: 40px;
}

.additional-details {
    margin-bottom: 30px;
}

.service-details{
    margin-top: 15px;
}

.notes{
    margin-bottom: 80px;
}

.details-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.details-grid .index-item-info{ 
    margin: 0;
}

.confirmation-header-container{
    display: flex;
    gap: 40px;
}

@media screen and (max-width: 899px) {
    .payment-confirmation-all{
        min-width: 275px;
        margin-top: 15px;
        max-height: 555px;
        overflow-y: auto;
    }

    .payment-confirmation{
        flex-direction: column;
    }

    .confirmation-header-container{
        flex-direction: column;
    }
}