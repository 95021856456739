.pricing-calculator{
    background-color: white;
    border-radius: 8px;
    display: flex;
    height: auto;
    box-shadow: 0 3px 10px rgba(0,0,0,.025);
}

.calculator-left{
    width: 55%;
    display: flex;
    flex-direction: column;
    border-right: 2px solid var(--secondary-white);
    padding: 15px;
    position: relative;
}

.pricing-calculator h3{
    font-size: 16px;
    margin-bottom: 10px;
}

.calculator-right{
    width: 40%;
    padding: 15px;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.calculator-main{
    width: 100%;
    min-height: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    position: relative;
    box-sizing: border-box;
    margin-top: 15px;
}

form{
    width: 100%;
}

.accept-button{
    margin: 20px 0 0 0;
    /* border-radius: 6px; */
    background-color: var(--primary-blue);
    width: 100%;
}

.accept-button:hover{
    background-color: var(--secondary-blue);
}

.subtotal{
    position: absolute;
    top: 15px;
    right: 30px;
    font-size: 22px;
    color: var(--primary-blue);
    font-weight: bold;
}

.textarea{
    min-height: 75px;
    resize: none;
    background-color: var(--secondary-white);
    border: 1px solid transparent;
    border: 1px solid var(--secondary-gray);
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
}

.pricing-disclaimers{
    margin-top: 20px;
    max-width: 500px;
}

.pricing-disclaimers > *{
    color: var(--primary-gray);
    font-size: 13px;
}

@media screen and (max-width: 699px){
    .pricing-calculator{
        display: block;
    }

    .calculator-left, .calculator-right{
        width: 95%;
        border: none;
    }
}