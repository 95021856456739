.order-index-item-expanded-container {
    display: flex;
    align-self: center;
    background-color: var(--primary-white);
    border-radius: 8px;
    margin-top: 4px;
}

.order-index-item-expanded-container h3 {
    font-size: 16px;
    margin-top: 10px;
}

.expanded-view-left,
.expanded-view-middle,
.expanded-view-right {
    padding: 0 30px;
    margin: 25px 0 15px 0;
    flex: 1;
}

.index-item-info,
.expanded-view-middle p {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 14px;
}

.expanded-view-middle,
.expanded-view-right {
    border-left: 2px solid var(--secondary-white);
}

@media screen and (max-width: 999px) {
    .order-index-item-expanded-container {
        flex-direction: column;
    }

    .expanded-view-left,
    .expanded-view-middle,
    .expanded-view-right {
        flex: unset;
        border: unset;
        border-top: 2px solid var(--secondary-white);
    }
}
