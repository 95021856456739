.dashboard-orders {
    overflow-x: auto;
    min-height: 600px;
    margin-top: 20px;
}

.dashboard-orders .upcoming-orders-index {
    border-radius: 10px;
}

.dashboard-orders h4 {
    font-weight: bold;
}

.dashboard-orders .table-header {
    display: flex;
    border-radius: 10px;
    height: 50px;
}

.dashboard-orders .table-header h3 {
    font-size: large;
}

.dashboard-orders .table-header-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    text-align: center;
    min-width: 150px;
    margin: 0 auto;
}

.dashboard-orders .table-header-column:first-child {
    min-width: 60px;
    margin: 0;
    margin-right: auto;
}  

.dashboard-orders .table-header-column:last-child {
    min-width: 100px;
    margin: 0;
    margin-left: auto;
}

.dashboard-orders .order-index-item-expanded-container{
    padding: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
    margin: 2px 0 20px 0;
    border-radius: 2px;
}

.dashboard-orders .reschedule-appointment-button {
    width: 125px;
    margin: 0;
    margin-top: 5px;
}

.dashboard-orders .orders-selector .vendor-custom-select {
    width: 150px;
    align-self: center;
}

.dashboard-orders .orders-selector .orders-selector-container {
    display: flex;
    width: 100%;
    justify-content: end;
}




.loading-orders-gif-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-spinner-orders {
    height: 50px;
    width: 50px;
}