.reviews{
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    background-color: white;
    position: relative;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
}

.reviews-header {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.reviews-header-left {
    display: flex;
    align-items: center;
    gap: 5px;
}

.vendor-review-button{
    background-color: var(--primary-green);
    /* color: black; */
    width: auto;
    height: auto;
    font-size: 12px;
    padding: 7px 10px;
    border-radius: 4px;
    position: relative;
}

.vendor-review-button:hover{
    background-color: var(--secondary-green);
}

.review-tag{
    color: var(--secondary-gray);
    margin-top: 10px;
}

.provider-review-index{
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
}

.reviews .no-reviews-yet {
    font-size: 14px;
    height: 125px;
    min-width: 200px;
    border-radius: 5px;
    flex: 1 1;
    background-color: var(--secondary-white);
    color: var(--primary-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-weight: bold; */
    padding: 0 50px;
    box-sizing: border-box;
}

@media (max-width: 1000px) {
    .reviews .reviews-header .hover-tooltip {
        left: 0;
    }
}