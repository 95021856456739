#dashboard-pricing {
  line-height: 25px;
  display: flex;
  width: fit-content;
  margin: 20px auto;
  gap: 20px;
}

#dashboard-pricing h3, .pricing-calculator h3 {
  font-size: 16.5px;
  margin: 0;
}

#dashboard-pricing span {
  font-size: 14.5px;
}

#dashboard-pricing .min-price-div input {
  margin-left: 5px;
}

#dashboard-pricing input[type="number"] {
  font-size: 16px;
  width: 70px;
  outline: none;
}

#dashboard-pricing .dashboard-pricing-right {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-white);
  width: 230px;
  height: 150px;
  padding: 20px;
  gap: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0,0,0,.025);
}

.min-price-div span:first-child {
  margin-right: 10px;
}

/* Pricing Calculator */
#dashboard-pricing .pricing-calculator {
  width: 400px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  align-items: center;
  border-radius: 8px;
}

#dashboard-pricing .pricing-calculator-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#dashboard-pricing .pricing-calculator-top span {
  background-color: var(--secondary-white);
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

#dashboard-pricing .pricing-calculator-bottom {
  width: 100%;
  margin-top: 10px;
}

@media screen and (max-width: 725px) {
  #dashboard-pricing {
    flex-direction: column;
    align-items: center;
  }

  #dashboard-pricing .pricing-calculator {
    width: 330px;
  }

  #dashboard-pricing .vendor-custom-select {
    width: 250px;
  }
}