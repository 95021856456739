.stripe-connect-text {
    margin-left: 40px;
    margin-top: 40px;
    margin-right: 40px;
  }
  
  .stripe-component {
    /* border: 1px solid blue; */
    width: 100%;
  }

  .connected-stripe {
    display: flex;
    align-items: center;
    color: var(--primary-green);
    width: auto;
    font-weight: bold;
    margin: 15px 3px 15px 7px;
  }


  .payments {
    display: flex;
    /* border: black solid 1px; */
    min-height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color:var(--primary-white);
  }


  .payments p {
    margin: 25px 0;
    line-height: 1.5;
  }

  .payments-left {
    width: 80%;
    background-color: #A3D3E5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .payments-right {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  @media screen and (max-width: 1200px) {
    .payments {
      flex-direction: column;
    }

    .payments-left {
      width: unset;
    }
  }