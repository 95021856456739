.provider-show-container{
    background-color: var(--secondary-white);
    width: 100%;
    height: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    box-shadow: -5px 0 10px -5px rgba(0, 0, 0, 0.5);
}

.provider-show{
    position: relative;
    display: flex;
    width: 90%;
    box-sizing: border-box;
    min-width: 300px;
    max-width: 1500px;
    min-height: 65vh;
    min-height: 600px;
    padding: 20px;
    margin: 10px auto 100px auto;
}

.provider-show-right{
    display: flex;
    flex-direction: column;
    width: calc(60% - 15px);
    height: 100%;
}

.provider-show-left{
    /* position: sticky; */
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    width: 40%;
    min-width: 250px;
    /* top: 100px; */
    margin-right: 15px;
}

.provider-show-right > *{
    margin-bottom: 10px;
}

.provider-show-left > *{
    width: 100%;
    border-radius: 8px;
    margin: 0 15px 15px 0;
    padding: 15px;
    box-sizing: border-box;
}

.provider-logo-background{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    min-width: 100px;
    position: relative;
    background-color: white;
    border-radius: 8px;
    margin-right: 10px;
    padding: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
}

.provider-logo{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.provider-name{
    font-weight: 500;
    font-size: 27px;
}

.gallery-header{
    font-size: 16px;
}

.provider-gallery, .pricing-calculator{
    flex-shrink: 0;
    font-size: 16px;
    height: 200px;
    padding: 10px;
}

.provider-pricing, .provider-scheduling, .provider-summary{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
}

.provider-show .provider-show-right .anchor-style{
    background-color: unset;
    color: var(--primary-blue);
    text-decoration: underline;
}
.provider-show-right .green-out{
    background-color: var(--primary-green);
    color: white;
}

.provider-show .provider-show-right .anchor-style:hover{
    background-color: unset;
    color: var(--secondary-blue);
    cursor: pointer;
}


.provider-show-right .green-out:hover{
    background-color: var(--secondary-green);
}

.pricing-preview, .scheduling-preview, .scheduling-preview--confirmed, .pricing-preview--confirmed, .summary-preview{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    color: var(--primary-blue);
    font-size: 16px;
    border-radius: 8px;
    line-height: 20px;
}

.scheduling-preview--confirmed, .pricing-preview--confirmed, .summary-preview{
    font-size: 16px;
    display: block;
}

.green-text{
    color: rgba(99, 196, 102, 0.9);
}

.get-price-button, .schedule-button, .view-gallery-button, .summary-button, .add-to-cart-button{
    background-color: var(--primary-blue);
    width: 120px;
    height: 40px;
    font-size: 15px;
    flex-shrink: 0;
    flex-grow: 0;
}

.secondary-continue-button{
    background-color: white;
    color: var(--primary-blue);
    border: 2px solid var(--primary-blue);
}

.secondary-continue-button:hover{
    background-color: white;
    border-color: var(--secondary-blue);
    color: var(--secondary-blue)
}

.summary-button{
    background-color: var(--primary-yellow);
    color: white;
}

.summary-button:hover{
    background-color: var(--primary-yellow);
}

.get-price-button:hover, .schedule-button:hover{
    background-color: var(--secondary-blue);
}

.disclaimer{
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    color: var(--secondary-gray);
    font-size: 12px;
    padding-bottom: 10px;
}

.minimize{
    display: none !important;
}

.provider-price-icon{
    width: 55px;
    height: auto;
    margin-right: 5px
}

.provider-calendar-icon, .provider-summary-icon{
    width: 45px;
    height: auto;
    margin-right: 10px;
    margin-left: 3px;
}

.provider-summary-icon{
    width: 60px;
    transform: translate(-5%, -10%);
    margin: 0px;
}

.provider-summary{
    color: var(--primary-blue);
}

.provider-summary button{
    width: 120px;
    height: 40px;
    flex-shrink: 0;
    margin: 0 5px;
}

.pointer:hover{
    cursor: pointer;
}

@media screen and (max-width: 999px){
    .provider-show{
        flex-direction: column;
        margin: 0 0 100px 0;
        width: calc(100vw - 40px);
        height: 100%;
        width: 100%;
    }

    .provider-show-left, .provider-show-right{
        width: 100%;
        display: relative;
        top: unset;
    }

    .provider-show-right .hover-tooltip {
        left: 15px;
    }
}