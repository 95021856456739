.home-main {
    color: var(--primary-black);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    box-shadow: -5px 0 10px -5px rgba(0, 0, 0, 0.5);
    min-height: 600px;
}

.home-main .home-header {
    margin: 40px auto;
    width: 78%;
    max-width: 1200px;
    font-weight: bold;
    user-select: none;
}

.home-main .filter-buttons-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary-gray);
    border-radius: 1px;
    margin: 20px auto 40px auto;
    width: 78%;
    max-width: 1200px;
}

.home-main .filter-buttons-container div:hover{
    border-bottom: 2px solid var(--secondary-gray);
    margin-bottom: -1px;
}

.home-main .filter-buttons-container div {
    margin-right: 30px;
    padding-bottom: 10px;
}

.home-main .filter-buttons-container .active {
    border-bottom: 2px solid var(--primary-gray);
    color: var(--primary-blue);
    font-weight: bold;
    margin-bottom:-1px;
}

.home-main .filter-buttons-container span {
    font-size: 18px;
    cursor: pointer;
    color: var(--primary-gray);
    user-select: none;
}

.home-main .filter-buttons-container span:hover {
    color: var(--secondary-gray);
}

.home-main .vendor-index-container:first-child {
    margin-top: 30px;
}

.home-main
    > *:not(:first-child):not(:second-child):not(:last-child):not(
        .address-form-container
    )::after {
    content: '';
    width: 100%;
    border-bottom: 2px solid var(--secondary-white);
    display: block;
    margin: 50px 0 60px 0;
}

@media screen and (max-width: 649px) {
    .home-main .home-header {
        width: 94%;
        margin: 20px auto;
    }

    .home-main .filter-buttons-container {
        width: 94%;
        margin: 30px auto 20px auto;
    }

    .home-main {
        margin-left: 0;
        overflow-x: auto;
    }
}

@media screen and (max-width: 499px) {
    .home-main .filter-buttons-container {
        justify-content: space-evenly;
    }

    .home-main .filter-buttons-container div {
        margin-right: 0
    }
    
    .home-main .filter-buttons-container span {
        font-size: 12px;
    }
}