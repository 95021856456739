#reviews-carousel {
    justify-content: start;
}

#reviews-carousel .carousel-image {
    width: 300px;
    height: 550px;
}

.remove-carousel-arrows {
    .carousel-arrow {
        display: none;
    }
}

#reviews-carousel #review-show {
    width: 100vw;
    height: 550px;
    border-radius: 10px !important;
}

#reviews-carousel .confirm-modal {
    height: 500px;
}

@media (max-width: 750px) {
    #reviews-carousel .review-form {
        min-height: unset !important;
        min-width: unset !important;
    }

    #reviews-carousel .carousel-button {
        top: 85%;
    }
}
