.logo-step .upload-all-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  gap: 60px;

}

.logo-step .upload-all-div h3 {
  margin-bottom: 20px;
  font-size: 1rem;
}

.logo-step .upload-logo-image-div {
  display: flex;
  flex-direction: column;

}

.logo-step .logo-container {
  border-radius: 8px;
  /* box-shadow: 0 0 3px rgb(0 0 0 / 0.05), 0 0 10px -5px rgb(0 0 0 / 0.3); */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
  padding: 15px;
  display: inline-block;
  width: fit-content;
}

.logo-step .provider-logo {
  width: 234px;
  height: 234px;
  object-fit: contain;
}

.logo-step .upload-banner-image-div {
  flex-grow: 1;
}

.logo-step .upload-banner-image-div .header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo-step .upload-banner-image-div .header h3 {
  margin: 0;
}

.logo-step .vendor-index-item {
  width: 90%;
  max-width: 400px;
  margin: 0;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
}

.logo-step .vendor-index-item-meta-info h3 {
  margin-bottom: 2px;
}

.loading-spinner {
  height: 20px;
  width: 20px;
}