.radio-buttons{
    width: 100%;
    margin: 10px 0;
}

.radio-buttons{
    accent-color: var(--secondary-blue);
}

.radio-buttons li {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.radio-buttons li label{
    font-size: 14px;
    margin-top: 2px;
    margin-left: 2px;
}

