.orders-filter {
    position: relative;
}

.orders-filter svg {
    width: 28;
    height: 28;
    cursor: pointer;
    user-select: none;
    fill: var(--primary-blue);
}

.orders-filter #active {
    fill: var(--primary-yellow);
}

#orders-filter-dropdown {
    position: absolute;
    top: 30px;
    right: 0;
    height: 115px;
    padding: 10px;
}

.active-filter {
    font-weight: bold;
}
