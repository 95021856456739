.about-us-container{
    color: var(--primary-blue);
    display: flex;
    justify-content: center;
    margin-bottom: 100px
}

.about-us{
    width: 60%;
    line-height: 25px;
}

.about-us h2{
    margin: 20px 0;
}

.about-us li{
    list-style-type: circle;
    margin-left: 15px
}

.about-us h1{
    margin: 60px 0 40px 0;
}

.logo-about-us{
    fill: var(--primary-blue)
}

.logo-accent-about-us{
    fill: var(--primary-yellow)
}

@media screen and (max-width: 699px){
    .about-us{
        width: 90%;
    }
}