.gallery-header{
    margin: 15px 0 -8px 20px;
}

.provider-gallery{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;
    box-sizing: border-box;
}

.gallery-container{
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(0,0,0,.025);
    height: auto;
}

.provider-photo{
    height: 160px;
    width: 160px;
    margin: 8px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}

.provider-photo:hover{
    transform: scale(1.01);
}

.image-container {
    position: relative;
    display: inline-block;
}


