.hover-tooltip {
  background-color: rgba(50, 50, 50, 0.95);
  color: #fff;
  z-index: 5;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 6px;
  width: 200px;
  line-height: 18px;
  font-size: 13px;
  text-align: center;
  font-weight: 200;
}

.hover-container {
  position: relative;
  display: inline-block;
}

@media (max-width: 699px) {
  .hover-tooltip {
    transform: translateX(-20%);
  }
}
