.partial-refund-modal .order-info {
    margin: 10px 0 0 0;
}

.partial-refund-modal .order-info p {
    margin: 10px 0;
}

.partial-refund-modal form {
    display: flex;
    flex-direction: column;
}

.partial-refund-modal label {
    margin-bottom: 16px;
    font-size: 16px;
}

.partial-refund-modal input[type='number'] {
    width: 100%;
    margin: 10px 0;
}

.partial-refund-modal textarea {
    resize: none;
    padding: 10px;
    padding-bottom: 30px;
    width: 94%;
    margin-bottom: 10px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: var(--secondary-white);
}

.partial-refund-modal .error {
    color: red;
    margin-bottom: 16px;
}


