footer{
    width: 100%;
    min-height: 150px;
    display: flex;
    justify-content: space-around;
    padding: 40px;
    box-sizing: border-box;
    background-color: var(--primary-white);
    box-shadow: inset 0px 5px 10px -9px rgba(0, 0, 0, 0.20);

}

.footer{
    display: flex;
    justify-content: flex-end;
    width: 70%;
    margin-top: aut;
}

.footer h3{
    font-size: 16px;
    margin-bottom: 10px;
}

.footer a{
    color: rgb(163, 160, 160);
    font-size: 14px;
    text-decoration: none;
}

.footer-column{
    height: 100%;
    display: flex;   
    flex-direction: column;
    margin: 0 20px;
}

.footer-column *{
    padding: 7px;
}

@media screen and (max-width: 599px){
    .footer{
        width: 100%;
    }
}

@media screen and (max-width: 430px){
    footer {
        padding: 0;
        padding: 10px 0;
    }
}