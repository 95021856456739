.confirm-cancel-order-modal .modal-header {
  margin-bottom: 10px;
}

.confirm-cancel-order-modal textarea {
  margin: 10px 0;
}

.confirm-cancel-order-modal #is-confirmed-with-customer-div {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
}

.confirm-cancel-order-modal #is-confirmed-with-customer-div #checkbox {
  width: 15px;
  margin: 0;
}

.confirm-cancel-order-modal #is-confirmed-with-customer-div label {
  margin-bottom: 0;
  font-size: 13px;
}

.confirm-cancel-order-modal #cancel-order-submit-button {
  margin-top: 20px;
}