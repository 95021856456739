.appointment-scheduling {
    min-height: 325px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 25px;
    /* position: relative; */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.025);
}

.appointment-scheduling h3 {
    font-size: 16px;
}

.scheduling-output {
    color: var(--primary-gray);
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
}

.appointment-scheduling .loading-times {
    height: 320px;
}

.appointment-scheduling .loading-spinner-orders {
    height: 75px;
    width: 75px;
}

.appointment-scheduling .react-datepicker {
    flex-shrink: 0;
    flex-grow: 0;
    margin: 30px 0;
    border-radius: 8px;
    border: none;
    font-family: 'Roboto', sans-serif;
    max-width: 567px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
    position: relative;
    min-width: 326px;
}

.scheduling-container {
    max-width: 567px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.scheduling-left {
    width: 70%;
    box-sizing: border-box;
}

.scheduling-right {
    width: 30%;
    box-sizing: border-box;
}

.react-datepicker__day {
    border-radius: 4px;
}

.react-datepicker__time-container {
    border: none !important;
}

.appointment-scheduling .react-datepicker__header {
    background-color: var(--secondary-white);
    border: none;
}

.appointment-scheduling .react-datepicker__current-month {
    margin: 10px 0 15px 0;
    font-size: 16px;
    color: var(--primary-blue);
}

.react-datepicker__aria-live {
    display: none;
}

.react-datepicker__day-names {
    background-color: var(--primary-blue);
}

.react-datepicker__day-names .react-datepicker__day-name {
    color: var(--primary-white);
}

.react-datepicker__day--today {
    font-weight: normal !important;
}

.react-datepicker__day {
    border: 1px solid transparent;
    box-sizing: border-box;
    padding: calc([ExistingPadding] - 1px);
}

.appointment-scheduling .react-datepicker__day--selected {
    color: white;
    background-color: var(--primary-blue);
    font-weight: normal;
    box-sizing: border-box;
}

.appointment-scheduling .react-datepicker__day--selected:hover {
    background-color: var(--secondary-blue);
    color: white;
    cursor: pointer;
}

.appointment-scheduling .react-datepicker__navigation--previous,
.appointment-scheduling .react-datepicker__navigation--next {
    height: 25px;
    border: transparent;
}

.appointment-scheduling .react-datepicker__navigation--next:hover,
.appointment-scheduling .react-datepicker__navigation--previous:hover {
    background-color: unset;
}

.appointment-scheduling .react-datepicker__time-list-item--disabled {
    display: none;
}

li.react-datepicker__time-list-item--selected {
    background-color: var(--primary-blue) !important;
    color: white !important;
    font-weight: normal !important;
}

li.react-datepicker__time-list-item--selected:hover {
    background-color: var(--secondary-blue) !important;
    color: white !important;
    font-weight: normal !important;
}

.react-datepicker__day--outside-month {
    color: #ccc !important;
    border: none !important;
}

.scheduling-continue-button {
    width: 300px;
    margin: 0 auto;
}

.scheduling-continue-button {
    width: 300px;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: var(--primary-blue);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

@media screen and (max-width: 699px) {
    .scheduling-container {
        max-width: 327px;
    }
}
