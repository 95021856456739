.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7.5% 0 7.5%;
    box-sizing: border-box;
    position: sticky;
    top: 115px;
    background-color: var(--secondary-white);
    z-index: 5;
    width: 100vw;
    height: 45px;
    align-self: center;
    box-sizing: border-box;
    box-shadow:
        0 3px 3px -2px rgba(0, 0, 0, 0.05),
        0 3px 10px -3px rgba(0, 0, 0, 0.1);
}

.dashboard-header h2 {
    font-size: 18px;
}

.dashboard-header button {
    background-color: var(--secondary-green);
    border-radius: 4px;
    height: 25px;
    width: 80px;
    margin: 0;
}

@media screen and (max-width: 699px) {
    .dashboard-header {
        top: 70px;
    }
}
