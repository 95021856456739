.provider-dashboard{
    color: var(--primary-blue);
    display: flex;
    justify-content: center;
    background-color: var(--secondary-white);
}

.provider-dashboard-container{
    width: 85%;
    min-height: 500px;
    border-radius: 10px;
    padding: 0 20px 50px 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
}

.subheader {
    z-index: 10;
    position: sticky;
    top: 70px;
    align-items: center;
    height: 45px;
    font-size: 1rem;
    color: var(--primary-gray);
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.05), 0 3px 10px -3px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-white);
    display: flex;
    justify-content: space-between;
}

.subheader-left {
    display: flex;
    margin-left: calc(5% + 20px);
    gap: 50px;
}

.subheader span {
    cursor: pointer;
}

.subheader span:hover {
    color: var(--secondary-gray);
}

.subheader .active {
    color: var(--primary-blue);
    font-weight: bold;
    box-shadow: none;
}

/* .dashboard-tabs{
    display: flex;
    position: absolute;
    top: -35px;
    left: 15px;
    width: 100%;
    cursor: pointer;
}

.dashboard-tabs li{
    width: 120px;
    height: 35px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--secondary-white);
    color: var(--primary-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-tabs .active{
    color: var(--primary-blue);
    font-weight: bold;
    box-shadow: none;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
} */

.servo-documentation{
    color: var(--secondary-gray);
    position: absolute;
    left: 30px;
    bottom: 20px;
}

@media screen and (max-width: 775px){
    .provider-dashboard-container{
        width: 100%;
        box-sizing: border-box;
    }

    .dashboard-tabs{
        left: 0;
    }

    .support-tab{
        display: none !important;
    }
}

@media screen and (max-width: 699px) {
    .subheader {
        display: none;
    }
}