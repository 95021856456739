.order-index-item-container {
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.05); */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.025);
    background-color: var(--primary-white);
    margin: 10px 0;
    border-radius: 8px;
    max-width: 100%;
    padding: 0 30px;
    line-height: 16px;
}

.order-index-item-container.active,
.order-index-item-container:hover {
    background-color: var(--tertiary-white);

    .order-index-item-expanded-container {
        background-color: var(--tertiary-white);
    }
}

.order-index-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.order-index-item h3 {
    font-size: 14px;
    color: var(--secondary-gray);
    font-weight: normal;
}

.order-index-item .logo-div {
    display: flex;
    align-items: center;
    margin: 0;
}

.order-index-item .logo-div img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    flex-shrink: 0;
}

.order-index-item .category-and-name {
    text-align: center;
    width: 250px;
}

.order-index-item-fulfillment,
.order-index-item-appointment,
.order-index-item-price {
    text-align: center;
}

.order-index-item-fulfillment p,
.order-index-item-appointment p,
.order-index-item-price p {
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
}

.order-index-item-appointment,
.order-index-item-fulfillment {
    width: 150px;
}

.order-index-item-price {
    display: flex;
    text-align: right;
    width: 75px;
}

.index-item-info p:nth-child(2) {
    margin-left: 10px;
    text-align: right;
}

.order-index-item-container .three-dots-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.order-index-item-container .three-dots-container svg {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 1099px) {
    .order-index-item {
        flex-wrap: wrap;
    }

    .order-index-item .logo-div {
        display: none;
    }

    .order-index-item .category-and-name {
        width: 200px;
    }

    .order-index-item-price {
        display: none;
    }

    .order-index-item .order-index-item-appointment,
    .order-index-item .order-index-item-fulfillment {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 500px) {
    .order-index-item .category-and-name,
    .order-index-item .order-index-item-appointment {
        width: 100%;
        margin-top: 20px;
    }
}
