.confirm-modal {
    display: flex;
    flex-direction: column;
    min-height: 29vh;
    box-sizing: border-box;
}

.confirm-modal .confirmation-text {
    padding: 10px 20px 0 0;
}

.confirm-modal .button-div {
    display: flex;
    flex-grow: 1;
}

.confirm-modal button {
    align-self: flex-end;
    margin: 0 auto;
    height: 40px;
    width: 45%;
}

.confirm-modal .cancel-button {
    background-color: var(--tertiary-gray);
}

.confirm-modal .submit-action-button {
    background-color: var(--secondary-red);
}

/* confirm-password-modal */
.confirm-password-modal .button-div {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.confirm-password-modal #confirm-password-modal-button {
    max-width: 47% !important;
    margin: 0;
}
