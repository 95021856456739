#vendor-sign-up-form h3 {
    margin: 3px 0 15px 0;
}

#vendor-sign-up-form span {
    color: var(--primary-blue);
    margin-bottom: 10px;
}

#vendor-sign-up-form p {
    text-align: center;
    color: var(--secondary-gray);
}

#vendor-sign-up-form input, #vendor-sign-up-form select {
    margin-bottom: 15px;
}

#vendor-sign-up-form .errors {
    color: var(--primary-yellow);
    margin: 0 auto;
    font-size: 14px;
}

#vendor-sign-up-form button {
    min-width: 300px;
    margin-top: 30px;
    background: var(--primary-blue);
    color: white;
}

#vendor-sign-up-form button:hover {
    cursor: pointer;
    background-color: var(--secondary-blue);
}

#vendor-sign-up-form .button-div {
    margin: 0 auto;
    margin-bottom: 5px;
}

#vendor-sign-up-form .prev-btn {
    background-color: var(--secondary-gray);
}

#vendor-sign-up-form .step1-top-div {
    display: flex;
    justify-content: center;
    gap: 20px;
    max-width: 100%;
}

#vendor-sign-up-form .step1-top-div input {
    width: 80%;
}

#vendor-sign-up-form .step2-bottom-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#vendor-sign-up-form .step2-bottom-div input, #vendor-sign-up-form .step2-bottom-div select {
    width: 30%;
    height: 40px;
}

#vendor-sign-up-form .step2-bottom-div input {
    margin-top: 5px;
}
