.review-index-item {
    background-color: var(--secondary-white);
    border-radius: 5px;
    margin: 2px;
    height: 100px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 1;
    user-select: none;
    cursor: pointer;
    /* for Verified Customer badge */
    position: relative; 
}

.review-index-item p {
    font-size: 12px;
    margin-top: 8px;
}

.review-index-item-top {
    margin: 5px 0;
    display: flex;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.review-index-item-top-left {
    display: flex;
    align-items: center;
}

.review-index-item-top h3 {
    font-size: 12px;
    text-wrap: nowrap;
}

.author-image-div {
    position: relative;
}

.google-author-img {
    height: 30px;
    margin-right: 10px;
}

.servo-name-circle {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-around;
    align-items: center;
    margin-right: 10px;
    color: white;
    font-size: 16px;
}

.author-image-div .icon {
    position: absolute;
    bottom: -3px;
    right: 4px;
    width: 17px;
    height: 17px;
}

.author-image-div #servo-icon-background {
    background-color: white;
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 8px;
    right: 9px;
    bottom: 0;
}

.author-image-div .servo-icon {
    width: 24px;
    height: 24px;
    margin: -3px -2px 0 0;
}

.review-stars-container {
    display: flex;
    align-items: center;
    margin-top: 3.5px;
}

.review-stars-container * {
    transform: scale(0.8);
    margin-right: -5px;
}

.review-index-item-body {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
}

.customer-verified-badge {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-yellow); 
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    text-wrap: nowrap;
}
