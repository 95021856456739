#update-password-form {
    padding: 25px;
    border-radius: 10px;
    margin-top: 40px;
    box-sizing: border-box;
    min-width: 420px;
    max-width: 400px;
    overflow-y: auto;
    color: var(--primary-blue);
    display: flex;
    flex-direction: column;
    background-color: var(--primary-white);
}

#update-password-form h2 {
    color: var(--primary-blue);
    margin: 20px 0;
    margin-bottom: 10px;
    font-size: 22px;
}

#update-password-form input {
    width: 100%;
    outline: none;
    margin-top: 15px;
}

#update-password-form .errors {
    color: var(--primary-yellow);
    display: block;
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
}

#update-password-form ul li {
    all: unset;
    display: list-item;
}

#update-password-form .suggestion {
    margin: 20px 0 10px 5px;
    font-size: 0.95rem;
}

#update-password-form #suggestions {
    display: block;
    list-style-type: disc;
    padding-inline-start: 15px;
    line-height: 20px;
}

#update-password-form .submit-button {
    margin: 0 auto;
    margin-top: 20px;
    width: 60%;
    background-color: var(--primary-blue);
    color: #fff;
    transition: background-color 0.3s ease;
}

#update-password-form .submit-button:hover {
    background-color: var(--secondary-blue);
}

