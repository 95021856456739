.forgot-and-reset-password-container {
  background-color: var(--secondary-white);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 600px;
}

.forgot-and-reset-password {
  background-color: var(--primary-white);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 450px;
  min-width: 350px;
  padding: 25px;
  border-radius: 10px;
  min-height: 400px;
  margin: 0 auto;
  margin-top: 40px;
  /* box-shadow: 0 0 3px rgb(0 0 0 / 0.05), 0 0 10px -5px rgb(0 0 0 / 0.3); */
  box-shadow: 0 3px 10px rgba(0,0,0,.025);
}

.forgot-and-reset-password h2 {
  color: var(--primary-blue);
  margin: 20px 0px;
  font-size: 24px;
}

.forgot-and-reset-password input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  width: 100%;
  outline: none;
}

.forgot-and-reset-password .errors {
  font-size: 14px;
  color: var(--primary-yellow);
  max-width: 345px;
  line-height: 1.1;
  text-align: center;
  margin: 40px auto -20px auto
}

.forgot-and-reset-password .success-msg {
  font-size: 14px;
  color: var(--primary-yellow);
  text-align: center;
}

.forgot-and-reset-password button {
  width: 60%;
  background-color: var(--primary-blue);
  color: #fff;
  transition: background-color 0.3s ease;
  margin: 30px auto 0 auto;
}

.forgot-and-reset-password button:hover {
  background-color: var(--secondary-blue)
}

/* .forgot-and-reset-password button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} */

/* for forgot pass only */
#forgot-password {
  min-height: 320px;
}

#forgot-password .success-msg {
  margin: 40px auto -20px auto
}

/* for reset pass form only */
.forgot-and-reset-password form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.password-reset .suggestion {
  margin: 20px 0 10px 0;
  font-size: .95rem;
  width: 100%;
}

.password-reset #suggestions {
  display: block;
  list-style-type: disc;
  padding-inline-start: 15px;
  line-height: 20px;
}

.password-reset #suggestions li {
  all: unset;
  color: var(--primary-blue);
  display: list-item;
}

.password-reset .errors {
  margin: 7px auto 0 auto
}

.password-reset .success-msg {
  margin: 40px auto -20px auto
}

@media (max-width: 699px) {
  .forgot-and-reset-password {
    max-width: 300px;
  }
}
