select{
    width: 100%;
    border-radius: 2px;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--secondary-white);
    border: none;
    padding: 0 1em;
    margin-top: 4px;
    color: var(--primary-blue);
    border-radius: 4px;
}

.custom-select{
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
    position: relative;
}

.select-wrapper{
    position: relative
}


.select-wrapper:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--primary-white); /* Arrow color */
    pointer-events: none;
}
