.meta-info-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0!important;
    padding: 0 !important;
    position: relative;
    align-items: stretch;
}

.meta-info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
    min-height: 120px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
    border-radius: 8px;
}

.meta-info-container h2{
    font-weight: lighter;
    color: var(--primary-gray);
    font-size: 20px;
}

.service-description-cancellation-container {
    line-height: 20px;
    font-size: 14px;
    display: flex;
    background-color: white;
    flex-direction: column;
    color: var(--primary-blue);
    width: 100%;
    padding: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
}

/* phone-number-email-container */
.phone-number-email-container {
    background-color: var(--primary-white);
    padding: 20px;
}

.phone-number-email-info-vendor {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.vendor-info-icon {
    height: 15px;
    width: 15px;
    margin-right: 5px;
}

/* service-description-cancellation-container */
.service-description-header {
    margin-bottom: 10px;
}

.cancellation-policy-text {
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
}

.meta-hr {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 1px;
    margin-left: 1px;
}
