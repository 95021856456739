#review-show {
    min-height: 35vh;
    padding: 25px !important;
    box-sizing: border-box;
    color: var(--primary-blue);
    display: block;
    overflow-y: auto;
}

#review-show h2 {
    user-select: none;
}

#review-show .review-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

#review-show .review-header .review-header-left {
    display: flex;
    align-items: center;
}

#review-show .author-image-div .google-author-img,
#review-show .author-image-div .servo-name-circle {
    height: 40px;
    width: 40px;
    font-size: 20px;
}

#review-show .author-image-div #servo-icon-background {
    bottom: 0;
    right: 12px;
}

#review-show #review-show-stars {
    transform: scale(1);
}

#review-show .review-stars-container {
    margin-left: 0;
    margin-bottom: 20px;
}

#review-show .date {
    margin-bottom: 20px;
}

#review-show #review-show-body {
    line-height: 18px;
    font-size: 0.9rem;
}

#review-show .delete-review {
    font-size: 0.7rem;
    width: fit-content;
    margin: 10px 0 5px 0;
}

#review-show .delete-review:hover {
    color: var(--primary-red);
}

/* #review-show .customer-verified-badge {
    bottom: 20px;
} */
