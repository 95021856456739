#vendor-order-index-item-container {
    margin-bottom: 2px;
}

#vendor-order-index-item-container:hover,
#vendor-order-index-item-container.active,
#vendor-order-index-item-container:hover .order-index-item-expanded-container,
#vendor-order-index-item-container.active .order-index-item-expanded-container,
#vendor-order-index-item-container:hover .vendor-order-index-item,
#vendor-order-index-item-container.active .vendor-order-index-item {
    background-color: var(--tertiary-white);
}

.dashboard-orders .vendor-order-index-item {
    display: flex;
    border-radius: 2px;
    background-color: var(--primary-white);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
    user-select: none;
    height: 55px;
    cursor: pointer;
    min-width: fit-content;
}

.dashboard-orders .vendor-order-index-item-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    min-width: 150px;
    margin: 0 auto;
}

.dashboard-orders .vendor-order-index-item-column:first-child {
    min-width: 60px;
    margin: 0;
    margin-right: auto;
}

.dashboard-orders .vendor-order-index-item-column:last-child {
    min-width: 100px;
    margin: 0;
    margin-left: auto;
}

.dashboard-orders .three-dots-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
}

.dashboard-orders .three-dots-container:hover{
    /* border: 1px solid var(--secondary-white); */
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .075);
    background-color: var(--primary-white);
}

.dashboard-orders .three-dots-container svg {
    width: 20px;
    height: 20px;
}

.dashboard-orders .upcoming-orders-index:first-child {
    background-color: var(--secondary-white);
}

@media screen and (max-width: 1270px) {
    #vendor-order-index-item-container {
        min-width: 910px;
    }
}

@media screen and (max-width: 999px) {
    .dashboard-orders .order-index-item-expanded-container {
        flex-direction: row;
    }

    .dashboard-orders .expanded-view-left,
    .dashboard-orders .expanded-view-middle,
    .dashboard-orders .expanded-view-right {
        flex: 1;
        border-top: unset;
    }

    .dashboard-orders .expanded-view-middle,
    .dashboard-orders .expanded-view-right {
        border-left: 2px solid var(--secondary-white);
    }
}
