.vendor-form-component .name-label {
  display: flex;
  align-items: center;
}

.vendor-form-component svg {
  margin-top: 5px;
}

.vendor-form-component .tooltip .tooltiptext {
  bottom: calc(100% + 3px);
  left: 250%;
  width: 200px;
}

.vendor-form-component .tooltip .tooltiptext::after {
  left: 50%;
}

.vendor-form-component .option-name {
  font-weight: bold;
}

/* VendorRadioButton */

.vendor-form-component .vendor-radio-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  margin-top: 10px;
}

.vendor-form-component .vendor-radio-button-div {
  display: flex;
}

.vendor-form-component .vendor-radio-button span {
  margin-top: 2px;
  margin-left: 2px;
}

/* VendorSelector */

.vendor-custom-select {
  position: relative;
  width: 330px;
  border-radius: 4px;
  padding: 7px;
  text-align: center;
  background-color: var(--primary-blue);
  color: var(--primary-white);
  cursor: pointer;
  font-size: 14.5px;
  user-select: none;
}

.vendor-custom-select .options-list {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid var(--secondary-white); 
  border-radius: 4px; 
  overflow: hidden;
  position: absolute;
  top: 100%; 
  left: 0;
  right: 0;
  z-index: 10; 
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--primary-blue);
}

.vendor-custom-select .option-item {
  display: flex;
  align-items: center;
  padding: 3px 15px;
  border-bottom: 1px solid var(--secondary-white);
  justify-content: space-between;
}

.vendor-custom-select .option-name {
  margin-right: 14px;
}

.vendor-custom-select .option-input {
  margin-left: 7px;
}