.vendor-index-item{
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    flex: 0 0 calc(33.33% - 20px);
    box-sizing: border-box;
    list-style: none;
    background-color: var(--secondary-white);
    /* box-shadow: 0 0 3px rgb(0 0 0 / 0.05), 0 0 10px -5px rgb(0 0 0 / 0.3); */
    box-shadow: 0 3px 10px rgba(0,0,0,.025);
}

.vendor-index-item h3{
    margin-bottom: 3px;
}

.vendor-index-image-container{
    height: 175px;
    min-width: 150px;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.vendor-index-image-container img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    object-fit: cover;
}

.vendor-index-item-meta-info{
    width: 100%;
    border-radius: 4px;
}

.vendor-index-item-meta-info-container{
    display: flex;
    align-items: center;
    padding: 12px;
}

.vendor-index-item-meta-info-container img{
    object-fit: contain;
    width: 55px;
    height: 55px;
    background-color: var(--primary-white);
    padding: 5px;
    border-radius: 5px;
    margin-right: 12px;
    flex-shrink: 0;
}

.macro-review-container p{
    display: inline;
}

.review-star-svg{
    margin: -2px 6px 0 2px;
    vertical-align: middle;
    transform: scale(0.9);
}

.macro-review-container :nth-child(1){
    font-weight: bold;
    color: var(--primary-gray);
    font-size: 13px;
}

.macro-review-container :nth-child(3){
    color: var(--secondary-gray);
    font-size: 12px;
}

@media screen and (max-width: 599px){
    .vendor-index-item{
        flex: 0 0 calc(50% - 20px);
    }

    .vendor-index-item-meta-info-container img{
        display: none;
    }

    .vendor-index-image-container{
        height: 150px;
    }
}