@import url('./reset.css');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

/* ------------------------------END_CSS_RESET-------------------------------- */
/* ---------------------------BEGIN_SITEWIDE_STYLES--------------------------- */

:root {
    --primary-blue: rgb(35 37 78);
    --secondary-blue: rgb(53 55 113);
    --primary-white: rgb(255 255 255);
    --secondary-white: rgb(247, 247, 247);
    --tertiary-white: rgb(255, 255, 255, 0.4);
    --primary-black: rgb(25 25 25);
    --secondary-black: rgb(73 73 73);
    --primary-gray: rgb(73 73 73);
    --secondary-gray: rgb(178, 178, 178);
    --tertiary-gray: #ccc;
    --primary-yellow: rgb(244 188 70);
    --primary-green: rgb(99 196 102 / 90%);
    --secondary-green: rgb(99 196 102);
    --primary-red: rgb(215, 23, 23);
    --secondary-red: rgb(192, 32, 0);
}

button {
    position: relative;
    width: 78px;
    height: 34px;
    color: var(--primary-white);
    background-color: var(--primary-blue);
    text-decoration: none;
    border-radius: 8px;
    font-size: 15px;
    padding: 0px 8px;
    white-space: nowrap;
    margin: 5px;
    font-weight: bold;
    border: none;
}

button:hover {
    background-color: var(--secondary-blue);
    cursor: pointer;
}

.gray-out:disabled {
    border-radius: 8px;
    background-color: var(--secondary-white) !important;
    color: var(--secondary-gray) !important;
    cursor: not-allowed !important;
    border-color: var(--secondary-white) !important;
    text-decoration: none !important;
}

.gray-out:disabled:hover {
    background-color: var(--secondary-white) !important;
    color: var(--secondary-gray) !important;
    cursor: not-allowed !important;
    border-color: var(--secondary-white) !important;
    text-decoration: none !important;
}

.gray-out-text {
    color: var(--secondary-gray) !important;
    cursor: not-allowed !important;
}

.gray-out-inverse:disabled {
    background-color: var(--tertiary-gray) !important;
    cursor: not-allowed !important;
    color: white !important;
}

header {
    position: sticky;
    top: 0;
    z-index: 20;
}

body {
    font-family: 'Roboto', sans-serif;
    color: var(--primary-white);
    height: 100%;
    width: 100%;
}

* {
    font-family: 'Roboto', sans-serif;
}

p {
    color: var(--primary-blue);
}

h1 {
    font-size: 30px;
    color: var(--primary-blue);
}

h2 {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-blue);
}

h3 {
    font-size: 15px;
    font-weight: bold;
    color: var(--primary-blue);
}

label {
    color: var(--primary-blue);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='number'],
input[type='time'],
input[type='email'] {
    border: none;
    border-radius: 4px;
    background-color: var(--secondary-white);
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 16px;
}

input[type='radio'],
input[type='checkbox'] {
    cursor: pointer;
}

input *,
label {
    user-select: none;
}

input,
select,
textarea {
    font-size: 16px;
}

section {
    margin-bottom: 15px;
}

strong {
    font-weight: bold;
}

a {
    text-decoration: underline;
    color: var(--primary-blue);
}

a:hover {
    color: var(--secondary-blue);
    cursor: pointer;
}

.fake-link {
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
}

.errors {
    color: var(--primary-yellow);
}
