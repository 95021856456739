.dashboard-scheduling{
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    line-height: 15px;
    box-sizing: border-box;
}

.calendar-header-container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.dashboard-scheduling h3{
    font-size: 16px;
    margin-bottom: 1px;
}

.dashboard-scheduling button {
    margin: 0;
}

.dashboard-scheduling-left{
    margin-right: 15px;
}

.dashboard-scheduling-right{
    flex: 1;
}

.dashboard-scheduling-left, .dashboard-scheduling-right{
    flex: 1;
    min-width: 300px;
}

.dashboard-scheduling-container{
    margin-bottom: 15px;
    background-color: var(--primary-white);
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(0,0,0,.025);
}

.dashboard-scheduling-container .tooltip .tooltiptext {
    margin-left: -132px;
}

.dashboard-scheduling-container .tooltip .tooltiptext::after {
    left: 50%;
}

.scheduling-input-container{
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.dashboard-scheduling input[type=number]{
    height: 30px;
}

.scheduling-min-max{
    color: var(--primary-gray);
    font-size: 11px;
    line-height: 25px;
}

@media screen and (max-width: 699px) {
    .dashboard-scheduling{
        flex-direction: column;
        align-items: center;
    }

    .dashboard-scheduling-left, .dashboard-scheduling-right{
        max-width: unset;
        width: 100%;
        margin: 0;
    }
}

.workday-incorrect-time-text {
    padding-top: 20px;
    color: var(--secondary-gray);
    padding-left: 10px;
}