.reschedule-modal-container {
    margin-top: 50px;
}

.reschedule-modal-container .scheduling-continue-button {
    width: 190px;
    height: 40px;
}

.reschedule-modal-container .appointment-confirmation-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.reschedule-modal-container .scheduling-container-loading {
    width: 400px;
    padding-bottom: 100px;
}

.reschedule-modal-container input[type='checkbox'] {
    margin-right: 5px;
    cursor: pointer;
}

.reschedule-modal-container .scheduling-container .react-datepicker {
    max-width: 325px;
    margin: 30px auto;
}

@media (min-width: 750px) {
    .reschedule-modal-container .scheduling-container .react-datepicker {
        max-width: 568px;
    }
}

@media (max-width: 699px) {
    .reschedule-modal-container .scheduling-container-loading {
        width: 90%;
        margin: 0 auto;
    }
}
