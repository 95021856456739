input[type=range]{
    width: 100%;
    cursor: pointer;
}

.range-slider-container{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.range-slider-container label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.range-slider{
    accent-color: var(--primary-blue);
    margin: 12px 0;
}