.home-nav{
    font-size: 15px;
    color: var(--primary-blue);
    background-color: var(--primary-white);
    display: flex;
    justify-content: space-between;
    height: 70px;
    width: 100%;
    user-select: none;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 20;
    box-shadow: 0 0 3px rgb(0 0 0 / 0.05), 0 0 10px -5px rgb(0 0 0 / 0.5);
}

.location-btn{
    background-color: var(--primary-yellow);
    font-size: 12px;
    max-height: 30px;
    padding-left: 11px;
    padding-right: 11px;
    margin-left: 20px;
    width: auto;
}

.location-btn:hover{
    background-color: var(--primary-yellow);
    cursor: auto;
}

.main-logo{
    width: 130px;
    margin: 0 10px;
}


/* ==========VALENTINES DAY THEME -- LOGO================= */

/* .main-logo #path68{
    fill: #e32948 !important;
}

.main-logo #path72{
    fill: #f1778c !important;
}

.main-logo #path76{
    fill: #e32948 !important;
}

.main-logo #path80{
    fill: #f1778c !important;
}

.main-logo #path84{
    fill: #e32948 !important;
} */


/* =========================== */
/* ==========ST PATRICKS DAY THEME -- LOGO================= */

/* .main-logo #path68{
    fill: #42b546 !important;
}

.main-logo #path72{
    fill: var(--primary-green) !important;
}

.main-logo #path76{
    fill: #42b546 !important;
}

.main-logo #path80{
    fill: var(--primary-green) !important;
}

.main-logo #path84{
    fill: #42b546 !important;
} */

/* =========================== */
/* ==========EASTER THEME -- LOGO================= */

/* .main-logo #path68{
    fill: #6de5df  !important;
}

.main-logo #path72{
    fill: #a8f75f !important;
}

.main-logo #path76{
    fill: #f4eb42 !important;
}

.main-logo #path80{
    fill: rgb(244 200 107) !important;
}

.main-logo #path84{
    fill: #f575cd!important;
} */
/* =========================== */
/* ==========4TH OF JULY THEME -- LOGO================= */

/* .main-logo #path68{
    fill: var(--primary-red)  !important;
}

.main-logo #path72{
    fill: var(--primary-blue) !important;
}

.main-logo #path76{
    fill: var(--primary-red) !important;
}

.main-logo #path80{
    fill: var(--primary-blue) !important;
}

.main-logo #path84{
    fill: var(--primary-red) !important;
} */
/* =========================== */

.hamburger-menu{
    fill: var(--primary-gray);
    color: var(--primary-gray);
    flex-shrink: 0;
}

.home-nav svg:hover{
    cursor: pointer;
}

.logo-container{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.home-nav-left{
    width: 40vw;
    display: flex;
    align-items: center;
    margin-left: 5%;
}

.home-nav-left-inner-container{
    width: 25vw;
    margin-left: 5%;
    display: flex;
    align-items: center;
}

.home-nav-right{
    width: 40vw;
    justify-content: end;
    display: flex;
    align-items: center;
    margin-right: -1%;
    margin-right: calc(5% + 20px);
    justify-content: space-between;
}

.address-or-logout-btn-div {
    /* width: 40%; */

    text-wrap: nowrap;
    text-align: center;
}

.user-address{
    text-align: right;
    line-height: 20px;
}

@media screen and (max-width: 599px) {
    .home-nav-right {
        margin-right: 20px;
    }

    .user-address{
        width: 150px;
        font-size: 15px;
        color: var(--primary-gray);
    }

    .location-btn{
        display: none;
    }
}

/* vendor nav */

#vendor-home-nav {
    box-shadow: none;
    border-bottom: .5px solid var(--secondary-gray);
}

#vendor-home-nav .vendor-name {
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 20px;
    text-wrap: nowrap;
}

#vendor-home-nav .vendor-name div {
    color: var(--secondary-gray);
    font-size: 14px;
}

#vendor-home-nav .home-nav-right {
    width: 40vw;
}

.home-nav .toggle-div {
    display: flex;
    /* width: 30%; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* margin-right: 30px; */
    color: var(--primary-gray);
}

.home-nav .toggle-div span {
    color: var(--primary-blue);
    text-wrap: nowrap;
}

#vendor-home-nav .vendor-logout {
    color: var(--primary-blue);
    background: transparent;
    font-weight: normal;
    border-bottom: 1.5px solid var(--primary-blue);
    border-radius: 0;
    padding-bottom: 2px;
    cursor: pointer;
}

#vendor-home-nav .vendor-logout:hover {
    color: var(--secondary-blue);
    border-bottom: 1.5px solid var(--secondary-blue);
}

.vendor-mobile-menu, #vendor-mobile-display {
    display: none;
}

@media screen and (max-width: 699px) {
    #vendor-home-nav .vendor-name, .home-nav .toggle-div, #vendor-home-nav .vendor-logout {
        display: none;
    }

    .vendor-mobile-menu {
        display: flex;
    }

    #vendor-mobile-display {
        display: flex;
        text-wrap: nowrap;
        padding-left: 40px;
        font-weight: bold;
    }
}