.toggle-div {
    margin-left: 50px;
}

.toggle-div .toggle-switch {
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    width: 40px;
    height: 20px;
    cursor: pointer;
}

.toggle-div .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondary-gray);
    border-radius: 20px;
    transition: 0.4s;
}

.toggle-div .toggle-switch.toggle-active .slider {
    background-color: var(--primary-yellow);
}

.toggle-div .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

.toggle-div .toggle-switch.toggle-active .slider:before {
    transform: translateX(20px);
}

.menu-backdrop .close-and-toggle-div {
    justify-content: space-between;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    margin: 0 20px;
    font-weight: normal;
}

.menu-backdrop .toggle-div span {
    margin-right: 10px;
}

.menu-backdrop .toggle-div {
    display: flex;
    align-items: center;
    margin-right: 15px;
}
