.images-step .add-images-text {
    margin-bottom: 20px;
}

.images-step .gallery-container {
    box-sizing: border-box;
    height: 50vh;
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    overflow-y: scroll;
    margin: 0 auto;
    background-color: var(--secondary-white);
}

.images-step .provider-logo-background-upload {
    min-width: 130px;
    width: 130px;
    height: 130px;
    min-height: 130px;
    margin-top: 7px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4.5px solid var(--primary-blue);
    border-radius: 8px;
    margin: 8px;
    cursor: pointer;
}

.images-step .image-container {
    position: relative;
    width: 160px;
    height: 160px;
}

.images-step .plus-icon {
    fill: var(--primary-blue);
}

.images-step .delete-button {
    position: absolute;
    top: 20px;
    right: 5px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    width: 15px;
    height: 15px;
}

/* media queries */
@media (max-width: 1268px) {
    .images-step .gallery-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1000px) and (max-width: 1184px) {
    .images-step .gallery-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 800px) and (max-width: 999px) {
    .images-step .gallery-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 830px) and (max-width: 966px) {
    .images-step .gallery-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 660px) and (max-width: 830px) {
    .images-step .gallery-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 660px) {
    .images-step .provider-photo {
        height: 120px;
        width: 120px;
    }

    .images-step .provider-logo-background-upload {
        min-width: 90px;
        width: 90px;
        height: 90px;
        min-height: 90px;
    }

    .images-step .delete-button {
        right: 5px;
    }

    .images-step .image-container {
        height: 120px;
        width: 120px;
    }

    .images-step .gallery-container {
        padding: 2px;
    }
}

@media (max-width: 596px) {
    .images-step .gallery-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 421px) {
    .images-step .gallery-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
