.secondary-nav-bar .user-view-banner{
    background-color: var(--primary-yellow);
    display: flex;
    justify-content: center;
    color: white;
    height: 35px;
}

.secondary-nav-bar .no-hover-effect:hover{
    color: var(--primary-blue);
}

.secondary-nav-bar .home-nav{
    box-shadow: none;
    border-bottom: .5px solid var(--secondary-gray);
    box-sizing: border-box;
}