.carousel {
    background-color: transparent;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 750px;
    height: 80vh;
    margin-top: 50px;
}

.carousel-image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 550px;
    min-height: 550px;
}

.carousel-image img {
    max-width: 550px;
    max-height: 550px;
    border-radius: 5px;
    user-select: none;
}

.carousel .carousel-button {
    background-color: transparent;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-arrow:hover {
    fill: var(--primary-blue);
}

.carousel-arrow {
    fill: var(--primary-white);
    height: 40px;
    width: 40px;
}

@media screen and (max-width: 750px) {
    .carousel .carousel-button {
        position: absolute;
        order: 1;
        top: 85%;
        right: 40%;
    }

    .carousel .carousel-button:first-child {
        right: 50%;
    }
}

@media screen and (max-width: 560px) {
    .carousel {
        flex-direction: column;
        margin-top: 0;
    }

    .carousel-image {
        min-width: 350px;
        min-height: 350px;
    }

    .carousel-image img {
        max-width: 350px;
        max-height: 350px;
    }

    .carousel .carousel-button {
        top: 80%;
    }
}
