.calculator-results{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.calculator-results h4{
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
    color: var(--primary-blue);
}

.vertical-line {
    width: 0px;
    height: 60px;
    border-left: 2px solid var(--secondary-white);
}

.calculated-price, .calculated-duration{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    font-size: 18px;
    font-weight: bold;
}

.calculated-price p, .calculated-duration p{
    color: var(--primary-gray);
}