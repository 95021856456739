.vendor-order-index-item-dropdown {
  background-color: white;
  width: 150px;
  /* height: 170px; */
  color: var(--primary-blue);
  padding: 15px;
  box-sizing: border-box;
  position: absolute; 
  top: 35px;
  right: 0; 
  z-index: 1;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .075);
  cursor: default;
}

.vendor-order-index-item-dropdown li {
  cursor: pointer;
  line-height: 28px;
}

.vendor-order-index-item-dropdown hr {
  border: none;
  height: 1px;
  background-color: var(--secondary-gray);
}

.vendor-order-index-item-dropdown .not-allowed {
  color: var(--secondary-gray) !important;
  cursor: not-allowed !important; /* not working w pointer-events: none; */
}

.vendor-order-index-item-dropdown .complete {
  color: var(--primary-green);
}