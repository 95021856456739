.orders{
    color: var(--primary-black);
    display: flex;
    justify-content: space-around;
    background-color: var(--secondary-white);
}

.orders-main-container{
    width: 70%;
    margin: 20px 0;
    min-height: 600px;
}

.orders .no-orders-yet {
    margin-top: 30px;
}

.loading-user-orders-gif-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.loading-spinner-user-orders {
    height: 40px;
    width: 40px;
}

@media screen and (max-width: 599px){
    .orders-main-container{
        width: 90%;
    }
}
