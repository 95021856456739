.dashboard-general {
    display: flex;
    margin-top: 40px;
}

.dashboard-general h2 {
    font-size: 17px;
}

.dashboard-general p {
    line-height: 20px;
}

.dashboard-general .general-header-generic {
    margin: 15px 0 10px 0;
}

.dashboard-general .general-info-container {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    margin-bottom: 5px;
    box-shadow: 0 3px 10px rgba(0,0,0,.025);
}

.dashboard-general .general-info-container p {
    font-size: 15px;
    margin-top: 5px;
}


/* tab-selector */
.dashboard-general .tab-selector {
    box-sizing: border-box;
    line-height: 30px;
    margin-right: 75px;
    font-weight: bold;
    font-size: 17px;
    background-color: var(--primary-white);
    padding: 15px 30px;
    border-radius: 8px;
    height: min-content;
}

.dashboard-general .tab-selector li:hover{
    color: var(--secondary-gray);
}

.dashboard-general .tab-selector .inactive {
    color: var(--primary-gray);
    font-weight: normal;
}

/* .dashboard-general .tab-selector h2:hover {
    color: var(--secondary-gray)
} */

.dashboard-general-current-tab { 
    display: flex;
    gap: 50px;
}

/* businessTab */

/* Business Information */
.dashboard-general .fake-link {
    font-size: 14px;
}

.dashboard-general .general-left > *, .general-right > * {
    margin: 7px 7px 7px 0;
}

.dashboard-general .general-business-info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    /* width: 420px; */
}

.dashboard-general .general-business-info-line {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dashboard-general .general-attribute {
    font-weight: bold;
}

.dashboard-general .info-form {
    display: flex;
    flex-direction: column;
}

.dashboard-general .info-form input {
    padding: 10px;
    margin: 5px;
}

.dashboard-general .info-form textarea {
    background-color: var(--secondary-white);
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px;
}

.dashboard-general .minimize {
    display: none !important;
}

.dashboard-general .edit-button {
    height: 28px;
    width: fit-content;
    margin: 10px 0 0 0;
    background-color: white;
    border: 2px solid var(--primary-blue);
    color: var(--primary-blue);
}

.dashboard-general .edit-button:hover {
    background-color: var(--secondary-white);
    border: 2px solid var(--primary-blue);
    color: var(--primary-blue)
}

.dashboard-general .general-info-edit-button {
    background-color: unset;
    color: var(--primary-blue);
    text-decoration: underline;
    margin: 0px;
    padding: 0px;
    width: unset;
    height: unset;
    font-weight: unset;
}

.dashboard-general .general-info-edit-button:hover {
    background-color: unset;
    color: var(--primary-blue);
}

.dashboard-general .info-submit-button {
    background-color: var(--primary-green);
    border: none;
    width: 80px;
    color: white;
}

.dashboard-general .info-submit-button:hover {
    background-color: var(--secondary-green);
    border: none;
    color: white;
}

.dashboard-general .update-password-general-div {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    background-color: unset;
}

.dashboard-general .general-service-description-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0 10px 0;
    width: 440px;
}

.dashboard-general .save-service-description {
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
}

.listing-status-header {
    margin-top: 20px;
}

.dashboard-general .bank-account-container {
    display: flex;
    justify-content: center;
    height: 30px;
}

.dashboard-general .not-connected-stripe {
    display: flex;
    align-items: center;
    color: var(--secondary-red);
    width: auto;
    font-weight: bold;
    margin: 0 3px 0 7px;
}

.dashboard-general .status-circle-red {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--secondary-red);
}

.dashboard-general .deactivate-listing-button {
    width: fit-content;
    margin: 0px;
    margin-top: 15px;
    background-color: var(--secondary-red);
    color: white;
}

.dashboard-general .deactivate-listing-button:hover {
    background-color: var(--secondary-red);
}

.dashboard-general .reactivate-listing-button {
    width: fit-content;
    margin: 0px;
    margin-top: 20px;
    background-color: var(--primary-green);
    color: white;
}

.dashboard-general .reactivate-listing-button:hover {
    background-color: var(--primary-green)
}

/* Direct Deposit Information */
.dashboard-general .custom-island {
    width: 450px;
    background-color: var(--primary-white);
    border-radius: 8px;
    min-height: fit-content;
    padding-bottom: 20px;
    box-shadow: 0 3px 10px rgba(0,0,0,.025);
    overflow-y: scroll;
}

.dashboard-general .direct-deposit-form {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
}

.dashboard-general .account-management {
    width: 400px;
    margin: 0 auto;
}

/* brandingTab */
.dashboard-general .general-header-gallery {
    margin-bottom: 15px;
}

.dashboard-general .banner-header {
    display: flex;
    align-items: center;
}

.dashboard-general .banner-header .tooltip {
    margin-top: 2px;
}

.dashboard-general .general-right .gallery-container {
    background-color: var(--primary-white);
}

.dashboard-general .general-right-bottom {
    display: flex;
    width: 770px;
    justify-content: space-between;
    margin-top: 20px;
}

.dashboard-general .vendor-index-item {
    width: 340px;
    margin: 0;
}

.dashboard-general .general-info-container-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
}

.dashboard-general .general-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 250px;
    height: 223px;
    max-width: 250px;
}

.dashboard-general .provider-logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* media queries */
@media (max-width: 1300px) {
    .dashboard-general {
        flex-direction: row;
        align-items: start;
        margin-top: 15px;
    }
    .dashboard-general-current-tab {
        flex-direction: column;
        justify-content: center;
    }
    .dashboard-general .gallery-container {
        grid-template-columns: repeat(4, 1fr) !important;
    }
}

@media screen and (max-width: 1150px) {
    .dashboard-general .general-right-bottom {
        justify-content: space-between;
        gap: 70px;
        flex-direction: column;
    }
    .dashboard-general .gallery-container {
        max-width: 600px;
    }
    .dashboard-general .general-right-bottom {
        justify-content: space-between;
        gap: 30px;
        max-width: 345px;
    }
}

@media screen and (max-width: 920px) {
    .dashboard-general {
        flex-direction: column;
        align-items: center;
    }
    .dashboard-general .tab-selector {
        margin: 0;
        margin-bottom: 20px;
    }
    .dashboard-general .user-account {
        height: 10px;
    }
}

@media screen and (max-width: 750px) {
    .dashboard-general .gallery-container {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media screen and (max-width: 505px) {
    .dashboard-general .general-info-container {
        width: 250px;
    }
    .dashboard-general .general-business-info-header, .dashboard-general .general-service-description-header {
        max-width: 290px;
    }
    .dashboard-general .custom-island {
        max-width: 0;
    }
    .dashboard-general .account-management {
        max-width: 300px; 
    }
    .dashboard-general .tab-selector {
        margin-bottom: 30px;
    }
    .dashboard-general .gallery-container {
        width: 300px;
        grid-template-columns: repeat(2, 1fr) !important;
    }
    .dashboard-general-current-tab {
        max-width: 300px;
    }
    .dashboard-general .general-right-bottom {
        max-width: 300px;
    }
    .dashboard-general .vendor-index-item {
        max-width: 300px;
    }
}
