.connect-google-calendar{
    width: auto;
    display: flex;
    align-items: center;
    width: 110px;
    justify-content: center;
    gap: 5px;
    margin: 20px 0 0 0;
    height: 30px;
    /* border-radius: 4px; */
    background-color: var(--primary-green);
}

.connect-google-calendar:hover{
    background-color: var(--secondary-green)
}

.connect-google-calendar svg{
    fill: white;
    height: 18px;
    margin-left: 2px;
}

.status-circle{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--primary-green);
}

.connect-google-calendar--disconnect{
    color: var(--primary-green);
    width: auto;
    font-weight: bold;
    margin: 0 3px 0 7px;
}

.calendar-button-container{
    display: flex;
    align-items: center;
}

.calendar-disconnect-button:hover{
    cursor: pointer;
}