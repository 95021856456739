.contact-us-container{
    color: var(--primary-blue);
    display: flex;
    justify-content: center;
    margin-bottom: 100px
}

.contact-us-form{
    display: flex;
    flex-direction: column;
}

.contact-us-form > *{
    margin: 10px 0;
    flex-shrink: 0;
    min-height: 40px;
}

.contact-us button{
    width: unset;
}

.contact-us textarea{
    border: none;
    background-color: var(--secondary-white);
    height: 100px;
    padding: 10px;
    box-sizing: border-box;
}

.contact-us{
    width: 60%;
    line-height: 25px;
}

.contact-us h2{
    margin: 20px 0;
}

.contact-us li{
    list-style-type: circle;
    margin-left: 15px
}

.contact-us h1{
    margin: 60px 0 40px 0;
}

@media screen and (max-width: 699px){
    .contact-us{
        width: 90%;
    }
}