.user-session-form {
    width: 450px;
    min-width: 350px;
    background-color: var(--primary-white);
    padding: 25px;
    border-radius: 10px;
    margin-top: 40px;
    box-sizing: border-box;
}

.user-session-form h2 {
    color: var(--primary-blue);
    margin: 20px 0px;
    font-size: 24px;
}

/* form-toggle */
.user-session-form .form-toggle {
    display: flex;
    justify-content: center;
    margin: 0 auto 20px auto;
    width: 180px;
    border-radius: 20px;
    gap: 5px;
    background-color: var(--secondary-white);
    color: black;
}

.user-session-form .form-toggle button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0;
    font-size: 16px;
    z-index: 0;
    background-color: var(--secondary-white);
    color: var(--primary-blue);
}

.user-session-form .form-toggle .active {
    color: var(--secondary-white);
    background-color: var(--primary-yellow);
    z-index: 1;
}

.user-session-form .form-toggle:hover {
    background-color: rgb(241, 241, 241);
}

.user-session-form .form-toggle:hover .not-active {
    color: var(--secondary-blue);
    background-color: rgb(241, 241, 241);
}

/* form */
.user-session-form form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.user-session-form input {
    margin-bottom: 20px;
}

.user-session-form p {
    color: var(--primary-gray);
    font-size: 14px;
    text-align: center;
}

.user-session-form .fake-link {
    color: var(--primary-blue);
    margin-top: -15px;
    margin-bottom: 10px;
    font-size: 14px;
}

.user-session-form .errors {
    margin: 0 auto -20px auto;
}

.user-session-form button {
    height: 35px;
    font-weight: 700;
    margin: 10px auto 20px;
    width: 80%;
    font-size: 16px;
}

@media (max-width: 699px) {
    .user-session-form {
        max-width: 360px;
    }
}
