.checkout-form {
    background-color: var(--primary-white);
    border-radius: 10px;
    min-height: 325px;
    min-width: 325px;
    max-width: 400px;
    width: 50vw;
    padding: 25px;
    margin-top: 40px;
    /* position: relative; */
    box-sizing: border-box;
}

#guest-sign-up-form {
    background-color: unset;
    max-height: unset;
    overflow-y: unset;

    li {
        all: unset;
        text-align: center;
        display: block;
        font-size: 14px;
    }
}

.checkout-form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.checkout-form form {
    position: relative;
}

.checkout-form h2 {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.servo-certified-icon {
    margin: 0px auto 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 325px;
}

.servo-certified-icon hr {
    width: 60px;
    border: 1px solid var(--primary-blue);
}

.checkout-form li {
    width: 100%;
    border: 2px solid var(--primary-blue);
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    accent-color: var(--primary-blue);
    font-weight: bold;
    margin-top: 10px;
}

.checkout-form li label {
    font-size: 15px;
}

.checkout-form li:hover {
    background-color: var(--secondary-white);
    cursor: pointer;
}

.checkout-form .anchor-style {
    background-color: unset;
    color: var(--primary-blue);
    text-decoration: underline;
    height: unset;
    width: unset;
    margin: 10px auto 0 auto;
    position: relative;
    padding: 0;
}

.checkout-form .anchor-style:hover {
    background-color: unset;
    color: var(--secondary-blue);
    text-decoration: none;
}

.previous-payment-methods-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
}

#payment-message {
    color: #df1b41;
    font-size: 16px;
    margin: 10px auto;
    text-align: center;
}

#success-message {
    color: var(--primary-green);
}