.vendor-onboarding-container{
    color: var(--primary-blue);
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.vendor-onboarding{
    width: 60%;
    line-height: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.vendor-onboarding h1{
    margin: 75px 0 40px 0;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
}

.onboarding-cta{
    width: 80%;
    justify-self: center;
    background-color: var(--primary-yellow);
}

.onboarding-cta:hover{
    cursor: pointer;
    background-color: var(--primary-yellow);
}

.vendor-onboarding h2{
    font-size: 30px;
}

.vendor-onboarding h3{
    margin: 30px 0 20px 0;
    font-size: 20px
}

@media screen and (max-width: 799px) {
    .vendor-onboarding{
        width: 90%;
    }
}