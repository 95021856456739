.main-nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    color: var(--primary-white);
    position: relative;
    z-index: 50;
}
  
.main-nav-logo{
  left: 5%;
  top: -10px;
  position: absolute;
  width: 310px;
  height: 170px;
  border-radius: 10px;
  cursor: pointer;
}

/* .main-nav-logo path{
  fill: white !important;
}

.main-nav-logo #path88{
  fill: var(--primary-yellow) !important;
} */

.main-nav-links{
  margin-right: 5%
}

.main-sign-in-button{
  background-color: var(--primary-yellow);
  color: white;
}

.main-sign-in-button:hover{
  background-color: var(--primary-yellow);
}

.main-sign-up-button {
  color: var(--primary-blue);
  background-color: var(--primary-white);
  min-width: 130px;
}

.main-sign-up-button:hover {
  color: var(--secondary-blue);
  background-color: var(--primary-white);
}

.logo-accent-blue{
  fill: var(--primary-yellow);
}

.logo-blue{
  fill: var(--primary-blue);
}

.logo-accent-white{
  fill: var(--primary-yellow);
}

.logo-white{
  fill: var(--primary-white);
}

@media screen and (max-width: 599px){
  .main-nav-links{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 45px;
  }

  .main-nav-bar{
    height: 80px;
  }

  .main-nav-logo{
    width: 200px;
    top: -25px
  }
}

/* about us nav bar */

#about-us-nav-bar {
  min-height: 10vh;
  box-shadow: 0 0 3px rgb(0 0 0 / 0.05), 0 0 10px -5px rgb(0 0 0 / 0.5);
  padding: 0 50px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  cursor: pointer;
}

#about-us-nav-bar .main-nav-logo {
  all: unset;
  transform: scale(1.40);
}

#about-us-nav-bar .main-nav-links {
  margin: 0;
}

#about-us-nav-bar .main-sign-up-button {
  background-color: var(--primary-blue);
  color: white;
}

@media screen and (max-width: 699px) {
  #about-us-nav-bar {
    top: 0;
    padding: 10px 15px;
    min-height: 12vh;
  }

  #about-us-nav-bar .main-nav-logo {
    padding-left: 9px;
  }
}
