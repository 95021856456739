.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.442);
    z-index: 1000;
}

.modal-content {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 100;
    overflow-y: hidden;
}

.no-scroll {
    overflow: hidden;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-header h2 {
    user-select: none;
}

/* modals on mobile take up full screen */
@media (max-width: 699px) {
    .user-session-form,
    .vendor-sign-up-form,
    #update-password-form,
    .review-form,
    .payment-gateway,
    .pricing-formula,
    .order-cancellation-confirmation,
    .customer-order-cancellation-confirmation,
    .reschedule-appointment,
    .reschedule-modal-container,
    .partial-refund,
    .order-completion,
    .checkout-form,
    #pricing-formula-modal,
    .carousel,
    .payment-confirmation-all {
        overflow-y: auto !important;
        min-height: 100vh !important;
        min-width: 100vw !important;
        margin: 0 !important;
        border-radius: 0 !important;
    }
}
