.terms-of-service-container{
    color: var(--primary-blue);
    display: flex;
    justify-content: center;
    margin-bottom: 100px
}

.terms-of-service{
    width: 60%;
    line-height: 25px;
}

.terms-of-service h2{
    margin: 20px 0;
}

.terms-of-service li{
    list-style-type: circle;
    margin-left: 15px
}

.terms-of-service h1{
    margin: 60px 0 40px 0;
}

.contact-info {
    background-color: #e2e2e2;
    padding: 15px;
    margin-top: 20px;
}

.contact-info p {
    margin: 10px 0;
}

.contact-info h2 {
    color: #333;
    margin-top: 0;
}

@media screen and (max-width: 699px){
    .terms-of-service{
        width: 90%;
    }
}