#sign-up-form input {
    margin-bottom: 14px;
}

#sign-up-form p {
    color: var(--primary-gray);
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0;
}

#sign-up-form .errors {
    margin-bottom: 10px;
}

#sign-up-form button {
    margin: 10px auto;
}

.sign-up-form-section {
    display: flex;
}

.sign-up-form-section > * {
    margin: 0;
    flex: 1;
    width: auto;
    min-width: none;
}
