.reset-password-complete {
  height: 600px;
  width: 100%;
  background-color: var(--secondary-white);
  display: flex;
  justify-content: center;
  padding-top: 40px;
  box-sizing: border-box;
}

.reset-password-complete-content {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 200px;
  background-color: var(--primary-white);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 0 3px rgb(0 0 0 / 0.05), 0 0 10px -5px rgb(0 0 0 / 0.3);
}

.reset-password-complete h2 {
  /* margin: 30px 0 20px 0; */
  margin-bottom: 30px;
  font-size: 22px;
}

.reset-password-complete p {
  line-height: 1.1;
}

.reset-password-complete button {
  margin: 0 auto;
  margin-top: 80px;
  width: 180px;
}