.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 245px;
  background-color: rgba(50, 50, 50, 0.95);
  font-size: 14px;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 5;
  bottom: calc(100% + 10px);
  left: 50%;
  margin-left: -150px;
  line-height: 18px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover{
  cursor: pointer;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 57%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--primary-gray) transparent transparent transparent;
}

