.errors {
    margin-bottom: -5px;
    margin-top: 5px;
    padding: 0px;
    /* display: flex;
    justify-content: space-around; */
}

.errors li{
    margin: 0px;
    color: var(--primary-yellow);
    padding: 0px;
    text-align: center;
}