.review-form {
    max-width: 450px;
    min-width: 300px;
    width: 45vw;
    background-color: var(--primary-white);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    z-index: 15;
    position: relative;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
}

.close-icon:hover {
    cursor: pointer;
}

.review-form h2 {
    margin: 10px 0;
    font-size: 24px;
}

.review-form h3,
.min-chars {
    font-weight: normal;
    color: var(--primary-gray);
    font-size: 18px;
    margin: 20px 0;
}

.review-form-header {
    display: flex;
    justify-content: space-between;
    margin: 25px 15px 0 15px;
}

.form-user {
    font-weight: bold;
    font-size: 14px;
}

.public-review-tag {
    background-color: rgb(231, 231, 231) !important;
    padding: 7px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: -10px;
    font-weight: bold;
}

.review-form form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--secondary-white);
    border-radius: 10px;
    min-height: 200px;
}

.review-form form * {
    background-color: transparent;
}

.review-form .star-rating-div {
    display: flex;
    /* justify-content: space-evenly; */
    gap: 30px;
    margin: 20px;
}

.star-rating-div .star {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.min-chars {
    font-size: 14px;
    line-height: 20px;
}

.review-body {
    border: none;
    min-height: 80px;
    resize: none;
    padding: 10px;
    outline: none;
}

.review-form .error {
    color: var(--primary-yellow);
    text-align: center;
    margin-bottom: 15px;
    font-size: 13px;
}

.review-form-footer {
    width: 100%;
    height: 70px;
    border-top: 0.5px solid var(--secondary-gray);
    background-color: var(--primary-white);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
}

.review-form button {
    margin-right: 20px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 699px) {
    .review-form {
        justify-content: start;
        gap: 30px;
    }
}
