.splash-page-section{
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--secondary-white);
/* padding: 30px; */
}

.splash-page-image{
    object-fit: cover;
    width: 600px;
    height: 350px;
    box-shadow: 0 3px 10px rgba(0,0,0, 0.2);
    margin: 0 30px;
}

.splash-page-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    text-align: center;
    margin: 0 40px;
}

.splash-page-logo{
    height: 120px;
    width: 120px;
    object-fit: contain;
    background-color: white;
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0,0,0, 0.1);
    box-sizing: border-box;
}

.splash-page-logo:hover{
    cursor: pointer;
}

.splash-page h2{
    font-size: 30px;
    margin-bottom: 20px;
}

.splash-page-h3{
    font-size: 23px;
    margin: 30px 0;
}

.splash-page .vendor-index-container>h3{
    font-size: 28px;
    margin: 20px 0 20px 10px;
}

.splash-page p{
    font-size: 18px;
    line-height: 25px;
}

.three-step-section{
    justify-content: center;
    align-items: flex-start;
    height: 310px;
    display: flex;
    max-width: 100%;
}

.splash-page-icon-image{
    height: 80px;
    flex-shrink: 0;
}

.logo-section{
    height: 300px;
    height: unset;
    position: relative;
}

.splash-page-logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px auto 30px auto;
}

.section-text{
    display: flex;
    flex-direction: column;
    max-width: 550px;
    justify-content: space-between;
}


@media screen and (max-width: 999px){
    .splash-page-section{
        flex-direction: column;
        height: unset;
        padding: 20px 0;
    }

    .splash-page-image{
        width: 100%;
        margin: -30px 0 0 0;
    }

    .three-step-section{
        height: unset;
        align-items: center;
        flex-direction: column;
    }

    .splash-page-icon{
        margin: 25px 0;
    }

    .splash-page-logo-container{
        margin: 20px 0;
    }

    .splash-page-logo{
        height: 100px; 
        width: 100px;
    }

    .section-text{
        width: unset;
        padding: 40px
    }
}