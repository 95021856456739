.dashboard-setup {
  width: 80vw;
  min-height: 80vh;
  display: flex;
  margin: 40px 0 150px 0;
  gap: 30px;
}

.dashboard-setup .current-step-div {
  border-radius: 5px;
  min-height: 500px;
  min-width: 270px;
  padding: 40px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.025);
  margin-bottom: 20px;
  flex-grow: 1;
}

.dashboard-setup .current-step-div h2 {
  font-size: 1.7rem;
  margin-bottom: 30px;
}

.dashboard-setup ul li {
  all: unset;
}

.dashboard-setup ul {
  display: block;
  list-style-type: disc;
  padding-inline-start: 15px;
}

.dashboard-setup ul li {
  display: list-item;
  cursor: pointer;
  margin-bottom: 5px;
}

.dashboard-setup ul .inactive ::marker {
  color: var(--primary-yellow);
}

.dashboard-setup .continue-button {
  margin: 0;
  margin-top: 40px;
}

/* next steps */

.dashboard-setup .next-steps {
  width: 87%;
}

.dashboard-setup .next-steps p {
  margin: 25px 0;
  line-height: 1.5;
}

.dashboard-setup .next-steps p:last-child {
  margin: 40px 0;
}


.dashboard-setup .payments p {
  margin: 25px 0;
  line-height: 1.5;
}

.dashboard-setup .payments p:last-child {
  margin: 40px 0;
}

.provider-logo {
  cursor: pointer;
}

.payouts-no-padding  {
  padding: 0 !important;
  /* height: auto; */
}

 .tab.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


@media screen and (max-width: 966px) {
  .dashboard-setup {
    flex-direction: column;
    width: 100vw;
  }

  .dashboard-setup-status-bar {
    width: 100vw;
  }

  .dashboard-setup-status-bar .account-status-div,
  .dashboard-setup-status-bar .status-box {
    width: unset;
  }
}

@media screen and (max-width: 600px) {
  .upload-all-div {
    flex-direction: column;
  }
}
