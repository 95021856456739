.vendor-index-container{
    margin: 40px auto;
    width: 80%;
    max-width: 1200px;
    user-select: none;
}

.vendor-index-container>h3{
    font-size: 25px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.vendor-index ul{
    display: flex;
    flex-wrap: wrap;
}

.vendor-index h1{
    margin: 18px;
    font-weight: bold;
    font-size: 25px;
}


.status-green{
    height: 10px;
    width: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    margin: 0 0 4px 10px;
}

@media screen and (max-width: 649px) {
    .vendor-index-container{
        width: 100%;
    }
}