.privacy-policy-container{
    color: var(--primary-blue);
    display: flex;
    justify-content: center;
    margin-bottom: 100px
}

.privacy-policy{
    width: 60%;
    line-height: 25px;
}

.privacy-policy h2{
    margin: 20px 0;
}

.privacy-policy li{
    list-style-type: circle;
    margin-left: 15px
}

.privacy-policy h1{
    margin: 60px 0 40px 0;
}

.contact-info {
    background-color: #e2e2e2;
    padding: 15px;
    margin-top: 20px;
}

.contact-info p {
    margin: 10px 0;
}

.contact-info h2 {
    color: #333;
    margin-top: 0;
}

@media screen and (max-width: 699px){
    .privacy-policy{
        width: 90%;
    }
}